.overlay {
  align-items: center;
  background-color: rgba($night, .25);
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;

  &__bubble {
    align-items: center;
    background-color: $color;
    border-radius: 50%;
    color: $day;
    display: flex;
    flex-flow: column nowrap;
    height: 18rem;
    justify-content: center;
    text-align: center;
    transition: background-color ease-in-out .2s;
    width: 18rem;

    @media #{$mq-m} {
      height: 22rem;
      width: 22rem;
    }

    @media #{$mq-l} {
      height: 26rem;
      width: 26rem;
    }

    &__text {
      padding: 2rem;
      width: 100%;
    }

    a {
      border-color: $day;
      color: $day;
    }

    a:hover {
      border-color: $day;
      color: $day;
    }

    p {
      @media #{$mq-m} {
        font-size: 1.375rem;
      }

      @media #{$mq-l} {
        font-size: 1.5rem;
      }
    }
  }
}
