.schedule-list {
  flex: 1 0 auto;
  padding: 6rem 2rem 0;
  width: 100%;

  &:last-child {
    padding-bottom: 6rem;
  }

  * {
    z-index: 10;
  }

  ul {
    @include margin-fix;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  li {
    border-top: .0625rem $dark solid;
    display: flex;
    flex-flow: column nowrap;
    padding: .75rem 0;
    width: 100%;

    &:last-child {
      border-bottom: .0625rem $dark solid;
    }

    @media #{$mq-s} {
      flex-flow: row nowrap;
    }
  }

  &__time {
    flex: 0 0 auto;
    padding: .75rem 0;
    width: 100%;

    @media #{$mq-s} {
      width: 33.333%;
    }

    @media #{$mq-m} {
      width: 25%;
    }

    @media #{$mq-l} {
      width: 20%;
    }
  }

  &__slots {
    display: flex;
    flex: 0 0 auto;
    flex-flow: column nowrap;
    width: 100%;

    @media #{$mq-s} {
      flex: 0 1 auto;
    }

    @media #{$mq-m} {
      flex-flow: row wrap;
    }

    &__slot {
      flex: 0 0 auto;
      padding: .75rem 0;
      width: 100%;

      @media #{$mq-s} {
        padding-left: .75rem;
      }

      @media #{$mq-m} {
        padding-right: .75rem;
        width: 50%;
      }

      @media #{$mq-l} {
        width: 33.333%;
      }
    }

    &__slot:last-child:first-child {
      flex: 1 0 auto;
    }
  }
}


.schedule-btn {
  flex: 1 0 auto;
  padding: 6rem 2rem 0;
  width: 100%;

  &:last-child {
    padding-bottom: 6rem;
  }

  * {
    z-index: 10;
  }

  @media #{$mq-m} {
    width: 50%;
  }

  a {
    align-items: center;
    background-color: $color;
    border-radius: 50%;
    color: $day;
    display: flex;
    flex-flow: column nowrap;
    height: 16rem;
    justify-content: center;
    margin: 0 auto;
    padding: 3rem;
    text-align: center;
    transition: background-color ease-in-out .2s;
    width: 16rem;

    &:hover {
      background-color: $dark;
    }

    blockquote {
      font-size: 1.375rem;
      margin-bottom: 0;
    }

    @media #{$mq-l} {
      height: 20rem;
      width: 20rem;

      blockquote {
        font-size: 1.75rem;
      }
    }
  }
}
