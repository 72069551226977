.btn {
  @include margin-fix;
  background-color: $dark;
  border: 0;
  color: $day;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding: .875rem 1.5rem .625rem;
  transition: background-color ease-in-out .2s;

  &:hover {
    background-color: $color;
  }

  &--invert {
    color: $day;

    &:hover {
      background-color: $day;
      color: $dark;
    }
  }

  &--large {
    padding: 1.375rem 3rem 1.125rem;
  }
}
