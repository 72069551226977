.gallery {
  display: block;
  flex: 0 0 auto;
  padding: 6rem 0 0;
  width: 100vw;

  @media #{$mq-s} {
    margin: 0 -2rem;
  }

  @media #{$mq-l} {
    //column-count: 3;
    margin: 0 -4rem;
  }

  &:last-child {
    padding-bottom: 6rem;
  }

  &__grid {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  &__item {
    width: 50%;
    z-index: 10;

    @media #{$mq-l} {
      width: 33.333%;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}
