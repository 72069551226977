.text {
  flex: 1 0 auto;
  padding: 6rem 2rem 0;
  width: 100%;

  &:last-child {
    padding-bottom: 6rem;
  }

  * {
    z-index: 10;
  }

  @media #{$mq-m} {
    width: 50%;

    &--fullwidth {
      width: 100%;
    }
  }

  .video {
    @include margin-fix;
    height: 0;
    margin-bottom: 3rem;
    margin-top: 3rem;
    max-width: 40rem;
    overflow: hidden;
    padding-top: 56.25%;

    @media #{$mq-m} {
      height: 22.5rem;
      padding-top: 0;
      width: 40rem;
    }

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  iframe,
  .form {
    @include margin-fix;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
