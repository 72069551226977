.menu {
  align-items: center;
  background-color: $dark;
  display: none;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;

  &.active {
    display: flex;
  }

  a {
    color: $day;
    font-size: 1.25rem;
    font-weight: 500;
    margin: .25rem auto;
    text-align: center;
    text-transform: uppercase;
    transition: color ease-in-out .2s;

    @media #{$mq-s} {
      font-size: 1.5rem;
    }

    @media #{$mq-m} {
      font-size: 1.75rem;
    }

    &:hover {
      color: $color;
    }
  }
}
