body {
  color: $dark;
  font-family: 'DinNextCondensed', sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.25;
}

a {
  color: $dark;
}

strong,
b {
  font-weight: 500;
}

em,
i {
  color: $color;
}

h1 {
  @include margin-fix;
  color: $day;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1rem;
  margin-top: 6rem;
  max-width: 60rem;
  text-transform: uppercase;

  @media #{$mq-s} {
    font-size: 2.25rem;
  }

  @media #{$mq-m} {
    font-size: 2.75rem;
  }

  @media #{$mq-l} {
    font-size: 3rem;
  }
}

h2 {
  @include margin-fix;
  display: block;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.125;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  max-width: 40rem;
  padding-top: 2rem;
  position: relative;
  text-transform: uppercase;

  &::before {
    background-color: $color;
    content: '';
    display: block;
    height: .25rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 3rem;
  }
}

h3 {
  @include margin-fix;
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.125;
  margin-bottom: .75rem;
  margin-top: 2rem;
  max-width: 40rem;

  sub {
    display: block;
    font-weight: 300;
  }
}

blockquote {
  @include margin-fix;
  display: block;
  font-size: 1.75rem;
  line-height: 1.125;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  max-width: 40rem;
  text-transform: uppercase;
}

p {
  @include margin-fix;
  display: block;
  hyphens: auto;
  margin-bottom: .75rem;
  margin-top: .75rem;
  max-width: 40rem;

  a {
    border-bottom: .0625rem $dark solid;
  }

  a:hover {
    border-color: $color;
    color: $color;
  }
}
