.logo {
  align-items: center;
  background-color: $color;
  background-image: url('../images/logo.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 4rem 4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  transition: background-color ease-in-out .2s;
  width: 4.5rem;
  z-index: 10000;

  &:hover {
    background-color: $dark;
  }

  @media #{$mq-s} {
    background-size: 5rem 5rem;
    height: 6rem;
    right: 3rem;
    top: 3rem;
    width: 6rem;
  }

  @media #{$mq-l} {
    right: 5rem;
    top: 5rem;
  }

  &::before {
    color: $day;
    content: 'Menü';
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-top: .25rem;
  }
}
