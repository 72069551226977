.meta {
  background-color: $color;
  display: flex;
  padding: 6rem 2rem 4rem;
  width: 100%;

  * {
    z-index: 10;
  }

  @media #{$mq-s} {
    padding: 6rem 4rem 4rem;
  }

  @media #{$mq-l} {
    padding: 6rem;
  }

  a {
    color: $day;
    font-weight: 500;
    margin-right: 1.5rem;
    text-transform: uppercase;
    transition: color ease-in-out .2s;
    white-space: nowrap;

    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }

    &:hover {
      color: $dark;
    }
  }
}
