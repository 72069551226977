.page {
  background-color: $light;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  min-width: 20rem;
  overflow-x: hidden;
  width: 100%;

  &::before {
    background-image: url('../images/lines.svg');
    background-position: center center;
    background-size: cover;
    content: '';
    display: block;
    height: 45rem;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    opacity: .4;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: scale(1.25);
    width: 80rem;
    z-index: 1;
  }

  main {
    display: flex;
    flex-flow: row wrap;

    @media #{$mq-s} {
      padding: 0 2rem;
    }

    @media #{$mq-l} {
      padding: 0 4rem;
    }
  }
}
