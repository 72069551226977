@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=78767acb-c0c6-4848-8f77-b87852a93325&fontids=694448,694454");

@font-face {
  font-family: 'DinNextCondensed';
  src: url('../fonts/DinNextLightCond.eot'),
       url('../fonts/DinNextLightCond.eot?#iefix') format('embedded-opentype'),
       url('../fonts/DinNextLightCond.woff') format('woff'),
       url('../fonts/DinNextLightCond.woff2') format('woff2'),
       url('../fonts/DinNextLightCond.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DinNextCondensed';
  src: url('../fonts/DinNextMediumCond.eot'),
       url('../fonts/DinNextMediumCond.eot?#iefix') format('embedded-opentype'),
       url('../fonts/DinNextMediumCond.woff') format('woff'),
       url('../fonts/DinNextMediumCond.woff2') format('woff2'),
       url('../fonts/DinNextMediumCond.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
