.members {
  color: $day;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  width: 100vw;
  background-color: $color;

  a {
    color: $day;
  }

  * {
    z-index: 10;
  }

  @media #{$mq-s} {
    margin: 0 -2rem;
  }

  @media #{$mq-l} {
    margin: 0 -4rem;
  }

  &__title {
    background-color: $color;
    flex: 0 0 auto;
    height: auto;
    padding: 4rem 2rem;
    width: 100vw;

    h2 {
      padding-top: 0;
    }

    h2::before {
      display: none;
    }

    @media #{$mq-s} {
      padding: 4rem;
    }

    @media #{$mq-m} {
      height: 25vw;
      width: 50%;
    }

    @media #{$mq-l} {
      height: 20vw;
      padding: 4rem 4rem 4rem 6rem;
      width: 40vw;
    }
  }
}

.member {
  display: block;
  flex: 0 0 auto;
  overflow: hidden;
  width: 50vw;

  @media #{$mq-s} {
    width: 33.333vw;
  }

  @media #{$mq-m} {
    width: 25vw;
  }

  @media #{$mq-l} {
    width: 20vw;
  }

  &:hover &__info {
    transform: translateY(0);
  }

  &__cover {
    background-position: center center;
    background-size: cover;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    width: 100%;
  }

  &__info {
    background-color: $dark;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 2rem;
    position: absolute;
    transform: translateY(+100%);
    transition: transform ease-in-out .2s;
    width: 100%;
  }
}

.text + .members,
.stories + .members {
  margin-top: 6rem;
}
