.title {
  background-color: $color;
  color: $day;
  display: flex;
  flex-flow: column nowrap;
  min-height: 50vh;
  width: 100vw;

  * {
    z-index: 10;
  }

  &--home {
    min-height: 100vh;
  }

  @media #{$mq-s} {
    margin: 0 -2rem;
  }

  @media #{$mq-m} {
    align-items: flex-end;
    flex-flow: row nowrap;
  }

  @media #{$mq-l} {
    margin: 0 -4rem;
  }

  &__text {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    padding: 2rem 2rem 8rem;

    @media #{$mq-s} {
      padding: 4rem 4rem 6rem;
    }

    @media #{$mq-m} {
      flex: 0 0 auto;
      height: 100%;
      width: 50%;

      &:last-child {
        flex: 1 0 auto;
      }
    }

    @media #{$mq-l} {
      padding: 6rem;
    }

    small {
      color: $dark;
      display: block;
      font-weight: 500;
      margin-bottom: auto;
      max-width: 16rem;
      text-transform: uppercase;
    }
  }

  &__cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0 auto;
    height: 100vw;
    width: 100%;

    @media #{$mq-m} {
      height: 50vw;
      width: 50%;
    }
  }
}
