html {
  font-size: 87.5%;

  @media screen and (min-width: 24em) {
    font-size: 100%;
  }

  @media screen and (min-width: 64em) {
    font-size: 100%;
  }

  @media screen and (min-width: 72em) {
    font-size: 105%;
  }

  @media screen and (min-width: 80em) {
    font-size: 110%;
  }

  @media screen and (min-width: 88em) {
    font-size: 115%;
  }

  @media screen and (min-width: 96em) {
    font-size: 120%;
  }

  @media screen and (min-width: 104em) {
    font-size: 125%;
  }

  @media screen and (min-width: 112em) {
    font-size: 130%;
  }

  @media screen and (min-width: 120em) {
    font-size: 135%;
  }

  @media screen and (min-width: 128em) {
    font-size: 140%;
  }

  @media screen and (min-width: 136em) {
    font-size: 145%;
  }

  @media screen and (min-width: 144em) {
    font-size: 150%;
  }

  @media screen and (min-width: 152em) {
    font-size: 155%;
  }

  @media screen and (min-width: 160em) {
    font-size: 160%;
  }
}
