.supporters {
  align-items: center;
  flex: 1 0 auto;
  padding-top: 6rem;
  width: 100%;

  &:last-child {
    padding-bottom: 6rem;
  }

  * {
    z-index: 10;
  }

  &__text {
    padding: 0 2rem;
    width: 100%;

    blockquote {
      border-top: .0625rem $dark solid;
      margin: 0 auto;
      max-width: 100%;
      padding-top: 2rem;
      text-align: center;
    }
  }

  &__logos {
    display: flex;
    flex-flow: row wrap;
    padding: 0 1rem;
    width: 100%;
  }

  &__logo {
    filter: grayscale(100%);
    flex: 0 0 auto;
    padding: 2rem 1rem 0;
    transition: filter ease-in-out .25s;
    width: 33.333%;

    &:hover {
      filter: grayscale(0);
    }

    @media #{$mq-s} {
      width: 25%;
    }

    @media #{$mq-m} {
      width: 20%;
    }

    @media #{$mq-l} {
      width: 16.666%;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}
