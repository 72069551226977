.cta-bar {
  align-items: center;
  background-color: $color;
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  padding: .5rem 1rem 1rem 2rem;
  position: fixed;
  right: 0;
  transition: background-color ease-in-out .2s;
  width: 100%;
  z-index: 100;

  &:hover {
    background-color: $dark;
  }

  @media #{$mq-s} {
    padding: .5rem 2rem 1rem 4rem;
  }

  @media #{$mq-l} {
    padding: .5rem 5rem 1rem 6rem;
  }

  &__item {
    color: $day;
    font-size: 1rem;
    font-weight: 500;
    margin: .5rem 1rem 0 0;

    @media #{$mq-s} {
      margin: .5rem 2rem 0 0;
    }
  }
}
