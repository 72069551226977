*,
*::before,
*::after {
  border: 0;
  box-sizing: border-box;
  font: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-size-adjust: 100%;
  vertical-align: baseline;
}
