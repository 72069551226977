.stories {
  flex: 1 1 auto;
  padding: 6rem 2rem 0;
  width: 100%;

  &:last-child {
    padding-bottom: 6rem;
  }

  * {
    z-index: 10;
  }

  @media #{$mq-m} {
    width: 50%;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__story {
    border-top: .0625rem $dark solid;

    &:last-child {
      border-bottom: .0625rem $dark solid;
    }

    a {
      display: block;
      padding-bottom: 1rem;
      padding-top: 1rem;
      transition: padding ease-in-out .2s;
    }

    a:hover {
      padding-left: 1.5rem;
    }
  }
}
