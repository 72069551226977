.cta {
  background-color: $color;
  color: $day;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  margin-top: 6rem;
  padding: 4rem 0 0;
  width: 100vw;

  @media #{$mq-s} {
    margin: 6rem -2rem 0;
    padding: 4rem 2rem 0;
  }

  @media #{$mq-m} {
    flex-flow: row nowrap;
  }

  @media #{$mq-l} {
    margin: 6rem -4rem 0;
    padding: 4rem 4rem 0;
  }

  .members + & {
    margin-top: 0;
  }

  &__item {
    padding: 2rem;
    width: 100%;
    z-index: 10;

    @media #{$mq-m} {
      width: 50%;
    }
  }
}
